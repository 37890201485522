import { css } from 'styled-components';

export const IbmplexsansNormalShark16px = css`
  color: var(--shark);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
`;

export const IbmplexsansMediumElectricViolet13px = css`
  color: var(--light-mode-purple);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-s);
  font-weight: 500;
  font-style: normal;
`;

export const IbmplexsansNormalWhite16px = css`
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
`;

export const IbmplexsansNormalElectricViolet16px = css`
  color: var(--light-mode-purple);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
`;
